import {Controller} from '@hotwired/stimulus';
import {useIntersection} from 'stimulus-use'

import { hasIntersectionObserver } from '../js/window-has';
import {backOff} from "../js/backoff";
import {consola as l} from "consola/basic";

export default class extends Controller {

    clickDone = false;

    static targets = ['closeButton', 'closeModal']

    async connect() {
        if (hasIntersectionObserver()) {
            useIntersection(this, {
                threshold: 1.0
            })
        } else {
            this.appear()
        }

        // cmp non loaded @fix here cmp cookieConsented cmp_consent_state
        if (!await cookieConsented()) {
            return;
        }

        backOff(() => {
            let videoType = 'articolo_ancorato';
            window.localStorage.setItem("tipo_video", videoType);

            let vast = new URL('https://pubads.g.doubleclick.net/gampad/ads?sz=640x360&iu=/35821442/calciomercato.com/tv/preroll&description_url=https%3A%2F%2Fwww.calciomercato.com%2F&tfcd=0&npa=0&gdfp_req=1&output=vast&unviewed_position_start=1&env=vp&impl=s&correlator=');
            try {
              let permutiveSegsVideoAd = window.localStorage.getItem("_pdfps") ? JSON.parse(window.localStorage.getItem("_pdfps")) : '';
              vast.searchParams.append('cust_params', 'tipo_video=' + videoType + '&permutive=' + permutiveSegsVideoAd);
            } catch (e) {
                // Do nothing.
            }

            // window.localStorage.setItem("vast", vast.href);
            window.vast = vast.href;
            window.tipo_video = videoType;

            this.element.classList.add('fixed')

            setTimeout(() => {
              this.closeButtonTarget.classList.remove('hidden')
            }, 16000)

        }, {
            maxDelay: 30_000,
            numOfAttempts: 10,
            startingDelay: 1000,
        }).catch(e => {
            l.error(e);
        });


    }


    forceRemoveFixed() {
        this.clickDone = true;
        this.removeFixed()
    }

    removeFixed() {
        this.closeModalTarget.classList.add('hidden')
        this.element.classList.remove('fixed')
    }

    addFixed() {
        this.closeModalTarget.classList.remove('hidden')
        this.element.classList.add('fixed')
    }

    appear() {
        this.removeFixed();
    }

    disappear() {
        if (!this.clickDone) {
            this.addFixed()
        }
    }

}
